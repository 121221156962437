@keyframes blink {
    0% {
        content: '';
        
    }

    25% {
        content: '.';
        opacity: 1;
    }

    50% {
        content: '..';
    }

    75% {
        content: '...';
        opacity: 1;
    }

    100% {
        content: '...';
        opacity: 0;
    }
}

.loading-dots {
    position: relative;
    display: inline-block;
    /* This makes sure that the container fits the content */
    /* overflow: hidden; Ensures that overflowing content is not shown */
}

.loading-dots::after {
    position: absolute;
    content: '';
    animation: blink 2s steps(5, end) infinite;
    left: 100%;
    /* Position the dots after the text */
    text-indent: 0.1em;
    /* Adjust this value to fit the width of the three dots */
}